import Image1 from '../../assets/omics.jpg'
import Image2 from '../../assets/codewhisperer.png'
import Image3 from '../../assets/steampipe-cover.jpg'
import Image4 from '../../assets/react-cover.png'
import Image5 from '../../assets/HealthLake-cover.jpg'
import Image6 from '../../assets/sagemaker-cover.jpg'
import Image7 from '../../assets/healthlake-use-case-cover.jpg'
import Image8 from '../../assets/lza-cover.jpg'
import Image9 from '../../assets/security-sra-cover.jpg'
import video from "../../assets/genomics.mp4"
import codewhispererVideo from "../../assets/codewhisperer.mp4"
import steampipeVideo from "../../assets/steampipe.mp4"
import reactVideo from "../../assets/react.mp4"
import lakeArchitectureVideo from "../../assets/healthlake-architecture.mp4"
import sageMakerVideo from "../../assets/sagemaker.mp4"
import lakeUseCasesVideo from "../../assets/healthlake-use-cases.mp4"
import vpcArchitecture from "../../assets/vpc-architecture.mp4"
import sra from "../../assets/sra.mp4"


import {FaRegPlayCircle} from 'react-icons/fa'



// console.log(video)
const data = [
    {
        id: 1,
        category: 'Healthcare & Genomics',
        image: Image1,
        title: "Amazon HealthOmics (Healthcare & Genomics)",
        desc: "In this video I explore Amazon omics, an aws service which enables life sciences organizations to store, query, analyze and gain insights into transcriptomic, proteomic, metabolomic and other omics datasets.",
        demo: video,
        github: 'https://youtu.be/rU38U4bPp1U?rel=0'
    },
    {
        id: 2,
        category: 'AI & ML',
        image: Image2,
        title: "CodeWhisperer (AI & ML)",
        desc: "In this video I explore AWS's CodeWhisperer, a newly released AI code generation tool which provides python code suggestions in VSCode, Cloud9 and the lambda console as well as performs security scans for vulnerabilities   ",
        demo: codewhispererVideo,
        github: 'https://youtu.be/rU38U4bPp1U?rel=0'
    },
    {
        id: 3,
        category: 'External Apps',
        image: Image3,
        title: "Steampipe (External Apps)",
        desc: "In this video I explore Steampipe, a plugin for querying AWS resources using SQL. Steampipe translates queries into API calls that are executed against the AWS service’s API. One compelling use case is compliance auditing.",
        demo: steampipeVideo
        //github: 'https://github.com/egattor'
    },
    {
        id: 4,
        category: 'External Apps',
        image: Image4,
        title: "React with Amplify (External Apps)",
        desc: "This video explores creating a React website which leverages Amplify for building and hosting, cognito for authentication and authorization and Route 53 for DNS services.",
        demo: reactVideo
        // github: 'https://github.com/egattor'
    },
    {
        id: 5,
        category: 'Healthcare & Genomics',
        image: Image5,
        title: "Amazon HealthLake (Healthcare & Genomics)",
        desc: "This video explores the architecture of Amazon HealthLake, a HIPAA-eligible service that enables storage, query, and analysis of healthcare datasets.",
        demo: lakeArchitectureVideo
        // github: 'https://github.com/egattor'
    },
    {
        id: 6,
        category: 'AI & ML',
        image: Image6,
        title: "SageMaker Predication Model (AI & ML)",
        desc: "This video explores the use of SageMaker's algorithms for predicting breast cancer from images of breast mass.",
        demo: sageMakerVideo
    },
    {
        id: 7,
        category: 'Healthcare & Genomics',
        image: Image7,
        title: "HealthLake Use Cases (Healthcare & Genomics)",
        desc: "This video explores the use cases for HealthLake, which can range from clinical trials to clinico-genomic research to predictive analytics and population health",
        demo: lakeUseCasesVideo
    },
    {
        id: 8,
        category: 'Architecture',
        image: Image8,
        title: "Landing Zone Accelerator (LZA) (Architecture)",
        desc: "This video highlights deployment of a secure, compliant and scalable multi-account AWS environment",
        demo: vpcArchitecture
        // github: 'https://github.com/egattor'
    },
    {
        id: 9,
        category: 'Architecture',
        image: Image9,
        title: "SRA (Architecture)",
        desc: "This video highlights the AWS Security Reference Architecture (SRA), a holistic set of guidelines for deploying the full complement of AWS security services in a multi-account environment",
        demo: sra
        // github: 'https://github.com/egattor'
    }
]


export default data