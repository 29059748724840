import Card from "../../components/Card"
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'
import { useState } from "react"


const FAQ = ({faq}) => {
    const [showAnswer, setShowAnswer] = useState(false);



  return (
    <Card className="faq" onClick={() => setShowAnswer(prev => !prev)}>
        <div>
            <h5 className="faq__question">{faq.question}</h5>
            <button className="faq__icon">
                {showAnswer ? <AiOutlineMinus/> : <AiOutlinePlus/>}
            </button>
        </div>
        {showAnswer ?
          ((typeof faq.answer === 'string' ? [faq.answer] : faq.answer) ?? []).map(e => <p key={e} className="faq__answer">{e}</p>) : null}
    </Card>
  )
}

export default FAQ