import AboutImage from '../../assets/about.png'
import CV from '../../assets/jhaney.pdf'
import { HiDownload } from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'
import HeaderImage from '../../assets/headShotPro.jpg'

const About = () => {
    return (
        <section id="about" data-aos="fade-in">
            <div className="container about__container">
                <div className="about__left">
                    <div className="about__portrait">
                        <img src={HeaderImage} alt="About Image" />
                    </div>
                </div>
                <div className="about__right">
                    <h2>About Me</h2>
                    <div className="about__cards">
                        {
                            data.map(item => (
                                <Card key={item.id} className="about__card">
                                    <span className='about__card-icon'>{item.icon}</span>
                                    <h5>{item.title}</h5>
                                    <small>{item.desc}</small>
                                </Card>
                            ))
                        }
                    </div>
                    <p>
                        My career journey of over 25+ years has taken me from implementing HL7 interfaces at Nellcor Puritan Bennet, to supporting patient care systems at MD Anderson Cancer Center, through overseeing informatics and LIMS platforms at the CDC, and finally to developing and deploying cloud-based microbiome and clinical genomics solutions at Emory University.
                    </p>
                    <p>
                        I hold a Master of Science in Public Health (MSPH) degree and an AWS Solutions Architect Associate (SAA-C03) certification. My B.S. degree is in Health Information Management, so I began my career coding medical records rather than coding applications.  
                    </p>
                    <p>
                        I have earned both an AWS Solutions Architect (SAA-C03) and an AWS Machine Learning Specialty (MLS-C01) certification.  
                    </p>   
                    <p> 
                        <strong>Verify Certifications: </strong> <a href="https://www.credly.com/users/ileen-jhaney/badges" target="_blank">https://www.credly.com/users/ileen-jhaney/badges</a>

                    </p>
                    <p>
                        I am interested in building cloud architectures that enable multi-omics and multi-modal data analytics, and am passionate about leveraging infrastructure as code (IaC) and CI/CD pipelines to build secure and performant Health AI/ML solutions.
                    </p>

                    <a href={CV} download className='btn primary'>Download CV <HiDownload /></a>
                </div>
            </div>
        </section>
    )
}

export default About