import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'
import {FaUniversity} from 'react-icons/fa'
import {FaBuilding} from 'react-icons/fa'

const data = [
    {id: 1, icon: <FaAward/>, title: 'AWS', desc: 'Certified Solutions Architect Associate SAA-C03'},
    {id: 2, icon: <FaUniversity/>, title: 'Education', desc: 'Master of Science in Public Health'},
    {id: 3, icon: <FaBuilding/>, title: 'Experience', desc: 'CDC & Emory University Winship Cancer Institute'}
    ]



    export default data;