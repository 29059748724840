import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'
import {FaAws,FaChartBar} from 'react-icons/fa'
import {FaStethoscope, FaDna} from 'react-icons/fa'

const data = [
    {
        id: 1, icon: <FaAws/>, title: 'Amazon Web Services', desc: "Design, develop and deploy secure, performant and scalable cloud-based public health and life sciences solutions"
    },
    {
        id: 2, icon: <FaStethoscope/>, title: 'Healthcare & Public Health', desc: "Implement cloud-based clinically driven solutions for hospital systems interoperability, HIPAA compliance and electronic health records management."
    },
    {
        id: 3, icon: <FaDna/>, title: 'Genomics', desc: "Build and deploy genomic pipelines and tools to support precision medicine, clinico-genomic research and population health"
    },
    {
        id: 4, icon: <FaChartBar/>, title: 'Analytics', desc: 'Apply machine learning and predictive analytics to uncover clinical insights and identify at-risk patients. Deploy infrastructure for machine learning as well as create machine learning models. '
    }
]


export default data