import {useState} from "react"  

import data from './data'
import {IoIosColorPalette} from 'react-icons/io'
import { useModalContext } from '../../context/modal-context'
import './navbar.css'
import { FaBars } from 'react-icons/fa';

const Navbar = ({signOut}) => {
  const {showModalHandler} = useModalContext();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(prev => !prev);
  };

  const closeHamburger = () => setHamburgerOpen(false);

  const hamburgerClass = (hamburgerOpen ?
    "hamburger-open" : "hamburger-closed") + " nav__menu";

  const navItems = data.map(item =>
    <li onClick={closeHamburger} key={item.id}>
      <a href={item.link}>{item.title}</a>
    </li>
  );

  return (
    <nav>
      <div className="container nav__container">
        <div className="hamburger" onClick={toggleHamburger}>
          <FaBars />
        </div>
        <ul className="nav__menu">{navItems}</ul>
        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginLeft: "1em"}}>
          <button id='theme__icon' onClick={showModalHandler}><IoIosColorPalette/></button>
          <button style={{cursor: "pointer", color: "white", backgroundColor: "transparent", marginLeft: "1em"}} onClick={signOut}>Sign out</button>
        </div>
      </div>      
      <ul className={hamburgerClass}>{navItems}</ul>
    </nav>
  );
};


export default Navbar
