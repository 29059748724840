/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:d9b0101f-2087-4665-b297-8a26e3addc2f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_adWilzHIY",
    "aws_user_pools_web_client_id": "776th7eag2i3q77l2v0s7um4is",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "amplifybio23project-20230604103743-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1avkn77a2mx6u.cloudfront.net"
};


export default awsmobile;
