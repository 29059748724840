import React from 'react';
import Image8 from '../../assets/lza-cover.jpg'


const data = [
    {
      id: 1,
      question: "What is your experience with writing, deploying, and managing CloudFormation templates? Have you used CloudFormation in a CI/CD pipeline? If so, please provide an example of how you used it", 
      
      answer: [
     "I use IaC tools (CloudFormation) to define and deploy secure research computing environments, enforcing security best practices (i.e. least privilege) and regulatory compliance. My expertise in AWS DevOps tools (CodeCommit, CodePipeline, CodeBuild, CodeDeploy) allows for streamlined development and automated disaster recovery using CI/CD pipelines and CloudFormation templates. These templates can deploy scaled-down infrastructure to a designated disaster recovery region, creating a warm standby environment ready for immediate failover. "
    ],
    },
    {
      id: 2,
      question: "Describe how you developed your react apps and which AWS services were used in their deployments?",
      answer: [
        "Amazon Cognito provides secure user authentication and authorization, and it manages user login. Amplify simplified the front-end development and deployment process. Route 53 handles the domain name registration and acts as my DNS service, directing users to my website portfolio.  CloudFront acts as a CDN, caching content at edge locations for faster loading times.",
      ],
    },
    {
      id: 5,
      question: "Which cloud computing roles do you see yourself in, and what would you like to achieve in those roles?",
      answer: [
        "I am passionate about AWS Cloud Engineer and Solutions Architect roles because I want to design and develop architectures for healthcare, life sciences, and clinicogenomics solutions. I am interested in working with multi-omics and multi-modal AWS platforms to synthesize genomic, clinical, mutation, expression, and imaging datasets. I want to use my expertise in AWS's Health AI services and my interest in CI/CD platforms to help organizations and companies realize their clinical, research, and service portfolio objectives."
      ],
    },
    {
      id: 4,
      question: "How can both HealthLake and HealthOmics AWS services contribute toward a comprehensive dataset for querying and machine learning?",
      answer: [
        "Access to VCF files in the HealthOmics Analytics data store and FHIR data in HealthLake is facilitated through AWS Lake Formation. In the case of Omics Analytics, shared tables connected to the HealthOmics Variant Store and Annotation Store are created in Lake Formation to enable querying of VCF files using Athena. Similarly, HealthLake utilizes LakeFormation to establish secure access to FHIR data, allowing the creation and management of shared tables in Amazon S3 that are associated with FHIR resources. Athena can be used to query both Omics Analytics and HealthLake through their respective shared tables created via LakeFormation. Consequently, Athena queries from both services can be combined seamlessly.", 
    ],
    },
    {
      id: 3,
      question: "How do you ensure that your AWS architectures are well-architected ?",
      answer: [
        "I ensure that my AWS architectures are well-architected by following the AWS Well-Architected Framework's six pillars: operational excellence, security, reliability, performance efficiency and cost optimization. I use a variety of AWS services to implement these best practices, such as CloudFormation, Config, CDK, Elastic Disaster Recovery, Compute Optimizer, Trusted Advisor, and Cost Explorer.  For example, for operational excellence I use infrastructure as code (CloudFormation) to create frequent, small, reversible changes.",
      ],
    },
    {
      id: 6,
      question: "How do you stay up-to-date on the latest cloud technologies and trends?",
    answer: [
        "AWS SkillBuilder which contains a wide variety of learning modes such as hands-on-labs, videos, certification reviews, etc.", 
        
        "The AWS Solutions Library is a great resource for pre-built solutions and CloudFormation templates",

        "I frequently check the AWS 'What's new feed' which contains announcements to keep users informed about the latest developments"

      ],
    },
    {
      id: 7,
      question: "What emerging AI/ML technology do you believe has the greatest potential to transform the healthcare landscape?",
      answer: [
        "I believe deep learning models have the capability to revolutionize digital pathology image analysis, offering transformative potential. Semantic segmentation provides for analyzing tissue samples pixel-by-pixel, and can automatically identify different structures with high accuracy. This allows for faster and more objective analysis of tumors, healthy cells, or specific molecules.  "

      ]
    },
    {
      id: 8,
      question: "If you could ask a former colleague or user to describe you in three words, what do you think they would say? Why?",
      answer: [
        "Professional, conscientious and focused.  Integrity and reliability are at my core. I am known for my meticulous attention to detail and commitment to quality. Being organized, extraordinarily detail-oriented, and dedicated to completing tasks to a high standard. Faced with challenging technical issues, I leverage my analytical skills to systematically deconstruct the problem, identify the root cause, and implement a solution", 

      ],
    }
  ]

  
  export default data